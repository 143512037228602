/**
 * Accordion (<details>)
 */

details {
  display: block;
  margin-bottom: var(--spacing);
  padding-bottom: calc(var(--spacing) * 0.5);
  border-bottom: var(--border-width) solid var(--accordion-border-color);

  summary {
    color: var(--accordion-close-summary-color);
    line-height: 1rem;
    list-style-type: none;
    cursor: pointer;

    @if $enable-transitions {
      transition: color var(--transition);
    }

    // Reset marker
    &::-webkit-details-marker {
      display: none;
    }

    &::marker {
      display: none;
    }

    &::-moz-list-bullet {
      list-style-type: none;
    }

    // Marker
    &::after {
      display: block;
      width: 1rem;
      height: 1rem;
      float: right;
      transform: rotate(-90deg);
      background-image: var(--icon-chevron);
      background-position: center;
      background-size: 1rem auto;
      background-repeat: no-repeat;
      content: "";

      @if $enable-transitions {
        transition: transform var(--transition);
      }
    }

    &:focus {
      outline: none;
      color: var(--accordion-active-summary-color);
    }

    ~ * {
      margin-top: calc(var(--spacing) * 0.5);

      ~ * {
        margin-top: 0;
      }
    }
  }

  // Open
  &[open] {
    > summary {
      margin-bottom: calc(var(--spacing) * 0.25);

      &:not(:focus) {
        color: var(--accordion-open-summary-color);
      }

      &::after {
        transform: rotate(0);
      } 
    }
  }
}

[dir="rtl"] {
  details {
    summary {
      &::after {
        float: left;
      }
    }
  }
}
